<template>
  <div>
    <b-container fluid class="content inventory">
      <b-row>
        <b-col>
          <b-card>
            <b-form>
              <el-row :gutter="10">
                <el-col :span="4">
                  <select class="form-control" v-model="cur_VendorIDn">
                    <option :value="0">{{ $t("Select Vendor") }}</option>
                    <option
                      v-for="(dt, idx) in vendorlist"
                      :key="idx"
                      :value="dt.IDn"
                    >
                      {{ dt.CompanyName }} - {{ dt.OtherName }}
                    </option>
                  </select>
                </el-col>
                <el-col :span="4">
                  <select class="form-control" v-model="cur_DepartmentIDn" @change="department_change">
                    <option :value="0">{{ $t("Select Department") }}</option>
                    <option
                      v-for="(dt, idx) in departmentlist"
                      :key="idx"
                      :value="dt.IDn"
                    >
                      {{ dt.Name1 }} - {{ dt.Name2 }}
                    </option>
                  </select>
                </el-col>
                <el-col :span="4">
                  <select class="form-control" v-model="cur_CategoryIDn">
                    <option :value="0">{{ $t("Select Category") }}</option>
                    <option
                      v-for="(dt, idx) in cur_categorylist"
                      :key="idx"
                      :value="dt.IDn"
                    >
                      {{ dt.Name1 }} - {{ dt.Name2 }}
                    </option>
                  </select>
                </el-col>
                <el-col :span="4">
                  <select class="form-control" v-model="cur_Brand">
                    <option :value="''">{{ $t("Select Brand") }}</option>
                    <option
                      v-for="(dt, idx) in brandlist"
                      :key="idx"
                      :value="dt"
                    >
                      {{ dt }}
                    </option>
                  </select>
                </el-col>
                <el-col :span="4" :offset="4">
                  <a :href="get_export_url()" target="_blank">
                    <el-button
                      type="primary"
                      class="mt-2"
                      icon="el-icon-document"
                      size="small"
                      >{{ $t("Export") }}</el-button
                    >
                  </a>
                </el-col>
              </el-row>
              <el-row class="mt-1" :gutter="10">
                <el-col :span="4">
                  <select class="form-control" v-model="cur_LocationID">
                    <option value="">{{ $t("Select Store") }}</option>
                    <option
                      v-for="(dt, idx) in storelist"
                      :key="idx"
                      :value="dt.LocationID"
                    >
                      {{ dt.LocationID }} - {{ dt.LocationName }}
                    </option>
                  </select>
                </el-col>
                <el-col :span="4">
                  <el-input
                    class="p-0"
                    v-model="cur_MinQty"
                    :placeholder="$t('Min. Qty')"
                  ></el-input>
                </el-col>
                <el-col :span="4">
                  <el-input
                    class="p-0"
                    v-model="cur_MaxQty"
                    :placeholder="$t('Max. Qty')"
                  ></el-input>
                </el-col>
                <el-col :span="4">
                  <select class="form-control" v-model="cur_Status">
                    <option value="">{{ $t("Product Status") }}</option>
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                  </select>
                </el-col>
                <el-col :span="4" :offset="4">
                  <el-button
                    type="primary"
                    class="mt-2"
                    icon="el-icon-search"
                    size="small"
                    @click="get_product_list"
                    >{{ $t("Search") }}</el-button
                  >
                </el-col>
              </el-row>
            </b-form>
          </b-card>
          <b-card class="mt-3">
            <p class="p-0" v-if="productlist">
              <el-table
                stripe
                class="table-responsive table"
                style="width: 100%"
                header-row-class-name="thead-light"
                :data="productlist"
                @row-click="row_clicked_event"
              >
                <el-table-column
                  :label="$t('Location')"
                  prop="LocationName"
                  class="p-0"
                  min-width="100px"
                >
                  <template v-slot:header>
                    <div class="w-100 p-0 text-center header_bg">{{ $t("Location") }}</div>
                    <el-input
                      class="p-0"
                      v-model="cur_LocationName"
                      @keyup.native="get_product_list_delay"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('PLU')" prop="PLU">
                  <template v-slot:header>
                    <div class="w-100 p-0 text-center header_bg">PLU</div>
                    <el-input
                      class="p-0"
                      v-model="cur_PLU"
                      @keyup.native="get_product_list_delay"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('Name')"
                  width="240"
                >
                  <template v-slot:header>
                    <div class="w-100 p-0 text-center header_bg">{{ $t("Name") }}</div>
                    <el-input
                      class="p-0"
                      v-model="cur_Name"
                      @keyup.native="get_product_list_delay"
                    ></el-input>
                  </template>
                  <template slot-scope="scope">
                    {{ shortenName(scope.row) }}
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('Ave Cost')"
                  prop="AverageCost"
                  align="right"
                >
                  <template v-slot:header>
                    <div class="w-100 p-0 text-center header_bg">{{ $t('Ave Cost') }}</div>
                    <el-input
                      class="p-0"
                      v-model="cur_AverageCost"
                      @keyup.native="get_product_list_delay"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('MSRP')" prop="APrice" align="right">
                  <template v-slot:header>
                    <div class="w-100 p-0 text-center header_bg">MSRP</div>
                    <el-input
                      class="p-0"
                      v-model="cur_APrice"
                      @keyup.native="get_product_list_delay"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('Min.')" prop="MinThreshold" align="right">
                  <template v-slot:header>
                    <div class="w-100 p-0 text-center header_bg">{{ $t('Min.') }}</div>
                    <el-input
                      class="p-0"
                      v-model="cur_MinThreshold"
                      @keyup.native="get_product_list_delay"
                    ></el-input>
                  </template>
                  <template slot-scope="props">
                    <el-input
                      v-model="props.row.MinThreshold"
                      size="mini"
                      @change="change_min_threshold(props.row)"
                      v-acl:InventoryEdit.enable
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('Max.')" prop="MaxThreshold" align="right">
                  <template v-slot:header>
                    <div class="w-100 p-0 text-center header_bg">{{ $t('Max.') }}</div>
                    <el-input
                      class="p-0"
                      v-model="cur_MaxThreshold"
                      @keyup.native="get_product_list_delay"
                    ></el-input>
                  </template>
                  <template slot-scope="props">
                    <el-input
                      v-model="props.row.MaxThreshold"
                      size="mini"
                      @change="change_max_threshold(props.row)"
                      v-acl:InventoryEdit.enable
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('In Stock')" align="right">
                  <template v-slot:header>
                    <div class="w-100 p-0 text-center header_bg">{{ $t('In Stock') }}</div>
                    <el-input
                      class="p-0"
                      v-model="cur_Qty"
                      @keyup.native="get_product_list_delay"
                    ></el-input>
                  </template>
                  <template slot-scope="props">
                    <el-tooltip class="item" effect="light" :content="$t('Can be Packed')" placement="top" v-acl:InventoryEdit.enable.show>
                      <i v-if="props.row.IsPack" @click="packing(props.row, 0)" class="el-icon-box" style="color: #497fe6"></i>
                    </el-tooltip>
                    <el-tooltip class="item" effect="light" :content="$t('In Combo')" placement="top" v-acl:InventoryEdit.enable.show>
                      <i v-if="props.row.IsCombo" @click="packing(props.row, 3)" class="el-icon-dish" style="color: #d05a20"></i>
                    </el-tooltip>
                    <el-tooltip class="item" effect="light" :content="$t('In Dispose')" placement="top" v-acl:InventoryEdit.enable.show>
                      <i v-if="props.row.IsDispose" @click="packing(props.row, 2)" class="el-icon-menu" style="color: #d05a20"></i>
                    </el-tooltip>
                    <el-tooltip class="item" effect="light" :content="$t('In BOM')" placement="top" v-acl:InventoryEdit.enable.show>
                      <i v-if="props.row.IsBOM" @click="packing(props.row, 1)" class="el-icon-tickets" style="color: #d05a20"></i>
                    </el-tooltip>
                    <span v-if="parseFloat(props.row.MaxThreshold) > 0">
                      <span
                        v-if="
                          parseFloat(props.row.Qty) >=
                          parseFloat(props.row.MinThreshold)
                        "
                      >
                        <span
                          v-if="
                            parseFloat(props.row.Qty) <=
                            parseFloat(props.row.MaxThreshold)
                          "
                        >
                          {{ props.row.Qty }}
                        </span>
                        <span v-else class="inventory-warrning">
                          {{ props.row.Qty }}
                        </span>
                      </span>
                      <span v-else class="inventory-warrning">
                        {{ props.row.Qty }}
                      </span>
                    </span>
                    <span v-else>
                      {{ props.row.Qty }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('On Hold')" prop="OnHold" align="right">
                  <template v-slot:header>
                    <div class="w-100 p-0 text-center header_bg">{{ $t('On Hold') }}</div>
                    <el-input
                      class="p-0"
                      v-model="cur_OnHold"
                      @keyup.native="get_product_list_delay"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('On Order')" prop="OnOrder" align="right">
                  <template v-slot:header>
                    <div class="w-100 p-0 text-center header_bg">{{ $t('On Order') }}</div>
                    <el-input
                      class="p-0"
                      v-model="cur_OnOrder"
                      @keyup.native="get_product_list_delay"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('Stock Value')"
                  prop="StockValue"
                  align="right"
                >
                  <template v-slot:header>
                    <div class="w-100 p-0 text-center header_bg">{{ $t('Stock Value') }}</div>
                    <el-input
                      class="p-0"
                      v-model="cur_StockValue"
                      @keyup.native="get_product_list_delay"
                    ></el-input>
                  </template>
                </el-table-column>
              </el-table>
              <b-pagination
                v-model="current_page"
                :total-rows="product_total"
                :per-page="per_page"
              ></b-pagination>
            </p>
            <p class="p-3" v-else>
              {{ $t("No Records") }}
            </p>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col> </b-col>
      </b-row>
    </b-container>

    <b-modal
      ref="my-modal"
      :title="$t('Inventory')+' / '+$t('Product Information')"
      id="mymodalclass"
      size="xl"
      lazy
      :ok-title="$t('Submit')"
      @ok="submit_update"
      @hide="my_hide"
    >
      <el-carousel
        ref="inventory_carousel"
        height="46rem"
        indicator-position="none"
        :autoplay="false"
        arrow="never"
        style="padding-top: 0;">
        <el-carousel-item name="inventory">
          <form>
            <el-row :gutter="10">
              <el-col :span="6">
                <b-input-group :prepend="$t('Name') + '1 : '">
                  <b-form-input v-model="cur_product.Name1" disabled></b-form-input>
                </b-input-group>
              </el-col>
              <el-col :span="6">
                <b-input-group :prepend="$t('Name') + '2 : '">
                  <b-form-input v-model="cur_product.Name2" disabled></b-form-input>
                </b-input-group>
              </el-col>
              <el-col :span="6">
                <b-input-group :prepend="$t('Min. Qty')+' : '">
                  <b-form-input
                    v-model="cur_inventory.MinThreshold"
                    type="number"
                  ></b-form-input>
                </b-input-group>
              </el-col>
              <el-col :span="6">
                <b-input-group :prepend="$t('Max. Qty')+' : '">
                  <b-form-input
                    v-model="cur_inventory.MaxThreshold"
                    type="number"
                  ></b-form-input>
                </b-input-group>
              </el-col>
            </el-row>
            <el-row class="mt-1" :gutter="10">
              <el-col :span="6">
                <b-input-group :prepend="$t('PLU')+' : '">
                  <b-form-input v-model="cur_product.PLU" disabled></b-form-input>
                </b-input-group>
              </el-col>
              <el-col :span="6">
                <b-input-group :prepend="$t('Barcode')+' : '">
                  <b-form-input
                    v-model="cur_product.Barcode"
                    disabled
                  ></b-form-input>
                </b-input-group>
              </el-col>
              <el-col :span="12">
                <b-input-group :prepend="$t('Remarks')+' : '">
                  <b-form-input v-model="cur_inventory.Remarks"></b-form-input>
                </b-input-group>
              </el-col>
            </el-row>
            <el-row :gutter="10" class="mt-1">
              <el-col :span="6">
                <b-input-group :prepend="$t('Store') + ' : '">
                  <b-form-input
                    v-model="cur_inventory.LocationName"
                    disabled
                  ></b-form-input>
                </b-input-group>
              </el-col>
              <el-col :span="6">
                <b-input-group :prepend="$t('Quantity')+' : '">
                  <b-form-input v-model="cur_inventory.Qty" disabled></b-form-input>
                </b-input-group>
              </el-col>
              <el-col :span="6">
                <b-input-group :prepend="$t('Last Cost')+' : '">
                  <b-form-input
                    v-model="cur_inventory.LatestCost"
                    disabled
                  ></b-form-input>
                </b-input-group>
              </el-col>
              <el-col :span="6">
                <b-input-group :prepend="$t('Ave Cost')+' : '">
                  <b-form-input
                    v-model="cur_inventory.AverageCost"
                    disabled
                  ></b-form-input>
                </b-input-group>
              </el-col>
            </el-row>

            <el-collapse class="detail-collapse">
              <el-collapse-item :title="$t('Product Detail')">
                
                <el-row :gutter="10" class="mt-1">
                  <el-col :span="6">
                    <b-input-group :prepend="$t('Name') + '1 : '">
                      <b-form-input v-model="cur_product.Name1" disabled></b-form-input>
                    </b-input-group>
                  </el-col>
                  <el-col :span="6">
                    <b-input-group :prepend="$t('Name') + '2 : '">
                      <b-form-input v-model="cur_product.Name2" disabled></b-form-input>
                    </b-input-group>
                  </el-col>
                  <el-col :span="6">
                    <b-input-group :prepend="$t('Department') + ' : '">
                      <b-form-input
                        v-if="cur_product.DepartmentIDn && departmentlist"
                        :value="departmentlist[cur_product.DepartmentIDn]['Name1']+' - '+departmentlist[cur_product.DepartmentIDn]['Name2']"
                        disabled
                      ></b-form-input>
                    </b-input-group>
                  </el-col>
                  <el-col :span="6">
                    <b-input-group :prepend="$t('Category') + ' : '">
                      <b-form-input
                        v-if="cur_product.CategoryIDn && categorylist"
                        :value="categorylist[cur_product.CategoryIDn]['Name1']+' - '+categorylist[cur_product.CategoryIDn]['Name2']"
                        disabled
                      ></b-form-input>
                    </b-input-group>
                  </el-col>
                </el-row>
                <el-row class="mt-1" :gutter="10">
                  <el-col :span="6">
                    <b-input-group prepend="PLU : ">
                      <b-form-input v-model="cur_product.PLU" disabled></b-form-input>
                    </b-input-group>
                  </el-col>
                  <el-col :span="6">
                    <b-input-group prepend="Barcode : ">
                      <b-form-input
                        v-model="cur_product.Barcode"
                        disabled
                      ></b-form-input>
                    </b-input-group>
                  </el-col>
                  <el-col :span="6">
                    <b-input-group prepend="SKU : ">
                      <b-form-input v-model="cur_product.SKU" disabled></b-form-input>
                    </b-input-group>
                  </el-col>
                  <el-col :span="6">
                    <b-input-group prepend="Status : ">
                      <b-form-input
                        v-model="cur_product.Status"
                        disabled
                      ></b-form-input>
                    </b-input-group>
                  </el-col>
                </el-row>
                <el-row class="mt-1" :gutter="10">
                  <el-col :span="6">
                    <b-input-group :prepend="$t('Description') + '1 : '">
                      <b-form-input
                        v-model="cur_product.Description1"
                        disabled
                      ></b-form-input>
                    </b-input-group>
                  </el-col>
                  <el-col :span="6">
                    <b-input-group :prepend="$t('Description') + '2 : '">
                      <b-form-input
                        v-model="cur_product.Description2"
                        disabled
                      ></b-form-input>
                    </b-input-group>
                  </el-col>
                  <el-col :span="6">
                    <b-input-group prepend="Brand Name : ">
                      <b-form-input v-model="cur_product.Brand" disabled></b-form-input>
                    </b-input-group>
                  </el-col>
                  <el-col :span="6">
                    <b-input-group prepend="Origin : ">
                      <b-form-input
                        v-model="cur_product.OriginCountry"
                        disabled
                      ></b-form-input>
                    </b-input-group>
                  </el-col>
                </el-row>
                <el-row class="mt-1" :gutter="10">
                  <el-col :span="6">
                    <b-input-group prepend="Net Weight : ">
                      <b-form-input
                        v-model="cur_product.NetWeight"
                        disabled
                      ></b-form-input>
                    </b-input-group>
                  </el-col>
                  <el-col :span="6">
                    <b-input-group prepend="Pack : ">
                      <b-form-input
                        v-model="cur_product_PackLMS"
                        disabled
                      ></b-form-input>
                    </b-input-group>
                  </el-col>
                  <el-col :span="6">
                    <b-input-group prepend="Unit : ">
                      <b-form-input v-model="cur_product.Unit" disabled></b-form-input>
                    </b-input-group>
                  </el-col>
                  <el-col :span="6">
                    <b-input-group prepend="Dimension : ">
                      <b-form-input
                        v-model="cur_product_DimLMS"
                        disabled
                      ></b-form-input>
                    </b-input-group>
                  </el-col>
                </el-row>
                <el-row class="mt-1" :gutter="10">
                  <el-col :span="6">
                    <b-input-group prepend="Create By : ">
                      <b-form-input
                        v-model="cur_inventory.CreateName"
                        disabled
                      ></b-form-input>
                    </b-input-group>
                  </el-col>
                  <el-col :span="6">
                    <b-input-group prepend="Create Time : ">
                      <b-form-input
                        v-model="cur_inventory_CreateDateTime"
                        disabled
                      ></b-form-input>
                    </b-input-group>
                  </el-col>
                  <el-col :span="6">
                    <b-input-group prepend="Update By : ">
                      <b-form-input
                        v-model="cur_inventory.UpdateName"
                        disabled
                      ></b-form-input>
                    </b-input-group>
                  </el-col>
                  <el-col :span="6">
                    <b-input-group prepend="Update DateTime : ">
                      <b-form-input
                        v-model="cur_inventory_UpdateDateTime"
                        disabled
                      ></b-form-input>
                    </b-input-group>
                  </el-col>
                </el-row>
              </el-collapse-item>
            </el-collapse>

            <el-tabs :value="cur_list_opt" @tab-click="list_opt_change">
              <el-tab-pane :label="$t('POS Sales')" name="pos">
                <div style="width: 100%;">
                  <el-table
                    stripe
                    class="data-grid table-responsive table"
                    header-row-class-name="thead-light"
                    style="width: 100%; padding: 0; overflow-y: visible; height: 31.5em;"
                    :data="cur_pos_list"
                  >
                    <el-table-column :label="$t('PLU')" prop="PLU" align="left">
                    </el-table-column>
                    <el-table-column :label="$t('Price')" prop="UnitPrice" align="right">
                    </el-table-column>
                    <el-table-column :label="$t('Qty')" prop="Qty" align="right">
                    </el-table-column>
                    <el-table-column :label="$t('Unit')" prop="UnitName" align="right">
                    </el-table-column>
                    <el-table-column :label="$t('Subtotal')" prop="ItemSubTotal" align="right">
                    </el-table-column>
                    <el-table-column :label="$t('Date')" :formatter="datetime_formatter" align="center">
                    </el-table-column>
                  </el-table>
                  <b-pagination
                    v-model="list_current_page"
                    :total-rows="list_total"
                    :per-page="list_per_page"
                  ></b-pagination>
                </div>
              </el-tab-pane>
              <el-tab-pane :label="$t('Inbound History')" name="ib">
                <div style="width: 100%;">
                  <el-table
                    stripe
                    class="data-grid table-responsive table"
                    header-row-class-name="thead-light"
                    style="width: 100%; padding: 0; overflow-y: visible; height: 31.5em;"
                    :data="cur_ib_list"
                  >
                    <el-table-column :label="'No. #'" align="left">
                      <template slot-scope="scope">
                        <span v-if="scope.row.OrderType != 'TR' && scope.row.OrderNo_1"><a href="#/inventory" @click="order_no_click(scope, 1)">{{ scope.row.OrderNo_1 }}</a></span>
                        <span v-else-if="scope.row.OrderNo_1">{{ scope.row.OrderNo_1 }}</span>
                        <span v-if="scope.row.OrderType != 'TR' && scope.row.OrderNo_2">{{ "(" }}<a href="#/inventory" @click="order_no_click(scope, 2)">{{ scope.row.OrderNo_2 }}</a>{{ ")" }}</span>
                        <span v-else-if="scope.row.OrderNo_2">{{ scope.row.OrderNo_2 }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column :label="$t('Cost')" prop="UnitCost" align="right">
                    </el-table-column>
                    <el-table-column :label="$t('Qty')" prop="OrderQty" align="right">
                    </el-table-column>
                    <el-table-column :label="$t('Unit')" prop="Unit" align="right">
                    </el-table-column>
                    <el-table-column :label="$t('Subtotal')" prop="TotalCost" align="right">
                    </el-table-column>
                    <el-table-column :label="$t('User')" :formatter="by_formatter" align="left">
                    </el-table-column>
                    <el-table-column :label="$t('Date')" prop="InboundDate" align="center">
                    </el-table-column>
                  </el-table>
                  <b-pagination
                    v-model="list_current_page"
                    :total-rows="list_total"
                    :per-page="list_per_page"
                  ></b-pagination>
                </div>
              </el-tab-pane>
              <el-tab-pane :label="$t('Outbound History')" name="ob">
                <div style="width: 100%;">
                  <el-table
                    stripe
                    class="data-grid table-responsive table"
                    header-row-class-name="thead-light"
                    style="width: 100%; padding: 0; overflow-y: visible; height: 31.5em;"
                    :data="cur_ob_list"
                  >
                    <el-table-column :label="'No. #'" align="left">
                      <template slot-scope="scope">
                        <span v-if="scope.row.OrderType != 'TR' && scope.row.OrderNo_1"><a href="#/inventory" @click="order_no_click(scope, 1)">{{ scope.row.OrderNo_1 }}</a></span>
                        <span v-else-if="scope.row.OrderNo_1">{{ scope.row.OrderNo_1 }}</span>
                        <span v-if="scope.row.OrderType != 'TR' && scope.row.OrderNo_2">{{ "(" }}<a href="#/inventory" @click="order_no_click(scope, 2)">{{ scope.row.OrderNo_2 }}</a>{{ ")" }}</span>
                        <span v-else-if="scope.row.OrderNo_2">{{ scope.row.OrderNo_2 }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column :label="$t('Price')" prop="UnitPrice" align="right">
                    </el-table-column>
                    <el-table-column :label="$t('Qty')" prop="OrderQty" align="right">
                    </el-table-column>
                    <el-table-column :label="$t('Unit')" prop="Unit" align="right">
                    </el-table-column>
                    <el-table-column :label="$t('Subtotal')" prop="TotalPrice" align="right">
                    </el-table-column>
                    <el-table-column :label="$t('User')" :formatter="by_formatter" align="left">
                    </el-table-column>
                    <el-table-column :label="$t('Date')" prop="OutboundDate" align="center">
                    </el-table-column>
                  </el-table>
                  <b-pagination
                    v-model="list_current_page"
                    :total-rows="list_total"
                    :per-page="list_per_page"
                  ></b-pagination>
                </div>
              </el-tab-pane>
              <el-tab-pane :label="$t('Packing History')" name="pak">
                <div style="width: 100%;">
                  <el-table
                    stripe
                    class="data-grid table-responsive table"
                    header-row-class-name="thead-light"
                    style="width: 100%; padding: 0; overflow-y: visible; height: 31.5em;"
                    :data="cur_pak_list"
                  >
                    <el-table-column :label="'No. #'" align="left">
                      <template slot-scope="scope">
                        <span v-if="scope.row.OrderNo"><a href="#/inventory" @click="order_no_click(scope, 0)">{{ scope.row.OrderNo }}</a></span>
                      </template>
                    </el-table-column>
                    <el-table-column :label="$t('Change') + ' ' + $t('Qty')" :formatter="unpack_formatter" align="right">
                    </el-table-column>
                    <el-table-column :label="$t('Relate') + ' ' + $t('Qty')" :formatter="unpack_formatter" prop="ToQtyChange" align="right">
                    </el-table-column>
                    <el-table-column :label="$t('Relate') + ' ' + $t('PLU')" :formatter="unpack_formatter" prop="ToPLU" align="left">
                    </el-table-column>
                    <el-table-column :label="$t('Relate')" :formatter="unpack_formatter" align="left">
                    </el-table-column>
                    <el-table-column :label="$t('User')" :formatter="by_formatter" align="left">
                    </el-table-column>
                    <el-table-column :label="$t('Date')" :formatter="datetime_formatter" align="center">
                    </el-table-column>
                  </el-table>
                  <b-pagination
                    v-model="list_current_page"
                    :total-rows="list_total"
                    :per-page="list_per_page"
                  ></b-pagination>
                </div>
              </el-tab-pane>
            </el-tabs>
          </form>
        </el-carousel-item>

        <el-carousel-item name="order">
          <el-button
            type="primary"
            icon="el-icon-back"
            @click="goBack"
            round
          >Back</el-button>
          <span class="order-title">{{cur_order_type ? cur_order_type + " " + $t("Order") + " # : " + cur_order.OrderID : "" }}</span>
          <br>
          <br>
          <form>
            <el-row :gutter="10">
              <el-col :span="8">
                <b-input-group :prepend="order_cust_name_func() + ' : '">
                  <b-form-input
                    :value="order_cust_func()"
                    disabled
                  ></b-form-input>
                </b-input-group>
              </el-col>
              <el-col :span="8">
                <b-input-group :prepend="$t('Store') + ' : '">
                  <b-form-input
                    :value="order_store_func()"
                    disabled
                  ></b-form-input>
                </b-input-group>
              </el-col>
              <el-col :span="8">
                <b-input-group :prepend="$t('Date') + ' : '">
                  <b-form-input
                    :value="order_date_func()"
                    disabled
                  ></b-form-input>
                </b-input-group>
              </el-col>
            </el-row>
            <el-row class="mt-1" :gutter="10">
              <el-col :span="8">
                <b-input-group :prepend="$t('Subtotal') + ' : '">
                  <b-form-input
                    :value="order_subt_func()"
                    disabled
                  ></b-form-input>
                </b-input-group>
              </el-col>
              <el-col :span="8">
                <b-input-group :prepend="$t('Total Tax') + ' : '">
                  <b-form-input
                    :value="order_tax_func()"
                    disabled
                  ></b-form-input>
                </b-input-group>
              </el-col>
              <el-col :span="8">
                <b-input-group :prepend="$t('Total') + ' : '">
                  <b-form-input
                    :value="order_total_func()"
                    disabled
                  ></b-form-input>
                </b-input-group>
              </el-col>
            </el-row>
            <br>
            <div style="width: 100%;">
              <el-table
                stripe
                class="data-grid table-responsive table"
                header-row-class-name="thead-light"
                style="width: 100%; padding: 0; overflow-y: visible; height: 40em;"
                :data="cur_order_products"
              >
                <el-table-column :label="$t('PLU')" prop="PLU" align="left">
                </el-table-column>
                <el-table-column :label="$t('Barcode')" prop="Barcode" align="left">
                </el-table-column>
                <el-table-column :label="$t('Name')" :formatter="name_formatter" align="left">
                </el-table-column>
                <el-table-column :label="$t('Unit')" :formatter="op_unit_formatter" align="right">
                </el-table-column>
                <el-table-column :label="$t('Price')" :formatter="op_price_formatter" align="right">
                </el-table-column>
                <el-table-column :label="$t('Qty')" :formatter="op_qty_formatter" align="right">
                </el-table-column>
                <el-table-column :label="$t('Subtotal')" :formatter="op_subt_formatter" align="right">
                </el-table-column>
              </el-table>
            </div>
          </form>
        </el-carousel-item>
      </el-carousel>
    </b-modal>

    <b-modal
      ref="packing-modal"
      :title="bom_title"
      id="mymodalclass2"
      size="xl"
      scrollable
      lazy
    >
      <template #modal-footer>
        <div>&nbsp;</div>
      </template>
      <div v-if="pack_type == 0 && pack_product">
        <div class="pack_unpack_div">
          <div class="mt-2">
            <el-row :gutter="10" style="font-weight: 600;">
              <el-col :span="5">
                <el-row :gutter="10">
                  <el-col :span="8" class="text-right lable_bold">
                    PLU:
                  </el-col>
                  <el-col :span="16">
                    {{ pack_product.PLU }}
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="5">
                <el-row :gutter="10">
                  <el-col :span="8" class="text-right lable_bold">
                    {{ $t("Name")+":"}}
                  </el-col>
                  <el-col :span="16">
                    {{ getName1Name2_1(pack_product) }}
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="5">
                <el-row :gutter="10">
                  <el-col :span="8" class="text-right lable_bold">
                    {{ $t("Package")+":" }}
                  </el-col>
                  <el-col :span="16">
                    {{ display_package(pack_product) }}
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="5">
                <el-row :gutter="10">
                  <el-col :span="8" class="text-right lable_bold">
                    {{ $t("Inventory")+":" }}
                  </el-col>
                  <el-col :span="16">
                    {{ get_inventory_qty(pack_product.IDn) }}
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
          <div v-if="pack_products && pack_products.length > 0" class="mt-2">
            <div class="mt-2">
              <el-row :gutter="10" v-for="prod in pack_products" :key="prod.IDn">
                <el-col :span="5">
                  <el-row :gutter="10">
                    <el-col :span="8" class="text-right lable_bold">
                      PLU:
                    </el-col>
                    <el-col :span="16">
                      {{ prod.PLU }}
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="5">
                  <el-row :gutter="10">
                    <el-col :span="8" class="text-right lable_bold">
                      {{ $t("Name")+":"}}
                    </el-col>
                    <el-col :span="16">
                      {{ getName1Name2_1(prod) }}
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="5">
                  <el-row :gutter="10">
                    <el-col :span="8" class="text-right lable_bold">
                      {{ $t("Package")+":" }}
                    </el-col>
                    <el-col :span="16">
                      {{ display_package(prod) }}
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="5">
                  <el-row :gutter="10">
                    <el-col :span="8" class="text-right lable_bold">
                      {{ $t("Inventory")+":" }}
                    </el-col>
                    <el-col :span="16">
                      {{ get_inventory_qty(prod.IDn) }}
                    </el-col>
                  </el-row>
                </el-col>
                <!-- -------------------------------------------- -->
                <el-col :span="4">
                  <el-row :gutter="10">
                    <el-col :span="16">
                      <el-input
                        class="p-0"
                        size="mini"
                        v-model="prod.pack_qty"
                        placeholder="Change Qty"
                        @input="check_packing(prod)"
                      ></el-input>
                    </el-col>
                    <el-col :span="4">
                      <el-tooltip class="item" effect="light" content="Increase Qty" placement="top">
                        <i @click="do_packing(prod, true)" class="el-icon-plus" style="color: #497fe6"></i>
                      </el-tooltip>
                    </el-col>
                    <el-col :span="4">
                      <el-tooltip class="item" effect="light" content="Decrease Qty" placement="top">
                        <i @click="do_packing(prod, false)" class="el-icon-minus" style="color: #497fe6"></i>
                      </el-tooltip>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      ref="bom-modal"
      :title="bom_title"
      id="mymodalclass2"
      size="xl"
      scrollable
      lazy
    >
      <template #modal-footer>
        <div>&nbsp;</div>
      </template>
      <div v-if="pack_type == 1 || pack_type == 2 || pack_type == 3">
        <div class="pack_unpack_div">
          <div v-if="pack_products && pack_products.length > 0">
            <el-card class="box-card mt-2" v-for="bom in pack_products" :key="bom.IDn">
              <div class="mt-2">
                <el-row :gutter="10" :style="get_font_weight(bom.ProductIDn)">
                  <el-col :span="5">
                    <el-row :gutter="10">
                      <el-col :span="8" class="text-right lable_bold">
                        PLU:
                      </el-col>
                      <el-col :span="16">
                        {{ bom.PLU }}
                      </el-col>
                    </el-row>
                  </el-col>
                  <el-col :span="5">
                    <el-row :gutter="10">
                      <el-col :span="8" class="text-right lable_bold">
                        {{ $t("Name")+":"}}
                      </el-col>
                      <el-col :span="16">
                        {{ getName1Name2_1(bom) }}
                      </el-col>
                    </el-row>
                  </el-col>
                  <el-col :span="5">
                    <el-row :gutter="10">
                      <el-col :span="8" class="text-right lable_bold">
                        {{ $t("Package")+":" }}
                      </el-col>
                      <el-col :span="16">
                        {{ display_package(bom) }}
                      </el-col>
                    </el-row>
                  </el-col>
                  <el-col :span="5">
                    <el-row :gutter="10">
                      <el-col :span="10" class="text-right lable_bold">
                        {{ $t("Inventory")+":" }}
                      </el-col>
                      <el-col :span="14">
                        {{ get_inventory_qty(bom.ProductIDn) }}
                      </el-col>
                    </el-row>
                  </el-col>
                  <el-col :span="4">
                    <el-row :gutter="10">
                      <el-col :span="16">
                        <el-input
                          class="p-0"
                          size="mini"
                          v-model="bom.pack_qty"
                          placeholder="Change Qty"
                          @input="check_bom(bom)"
                        ></el-input>
                      </el-col>
                      <el-col :span="4" v-if="bom.FromProducts">
                        <el-tooltip class="item" effect="light" content="Up" placement="top">
                          <i @click="do_bom(bom, true)" class="el-icon-arrow-up" style="color: #497fe6"></i>
                        </el-tooltip>
                      </el-col>
                      <el-col :span="4" v-if="bom.ToProducts">
                        <el-tooltip class="item" effect="light" content="Down" placement="top">
                          <i @click="do_bom(bom, false)" class="el-icon-arrow-down" style="color: #497fe6"></i>
                        </el-tooltip>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </div>
              <hr>
              <div class="mt-2" v-if="(pack_type == 1 || pack_type == 3) && bom.FromProducts">
                <el-row :gutter="10" v-for="prod in bom.FromProducts" :key="prod.IDn" :style="get_font_weight(prod.IDn)">
                  <el-col :span="5">
                    <el-row :gutter="10">
                      <el-col :span="8" class="text-right lable_bold">
                        PLU:
                      </el-col>
                      <el-col :span="16">
                        {{ prod.PLU }}
                      </el-col>
                    </el-row>
                  </el-col>
                  <el-col :span="5">
                    <el-row :gutter="10">
                      <el-col :span="8" class="text-right lable_bold">
                        {{ $t("Name")+":"}}
                      </el-col>
                      <el-col :span="16">
                        {{ getName1Name2_1(prod) }}
                      </el-col>
                    </el-row>
                  </el-col>
                  <el-col :span="5">
                    <el-row :gutter="10">
                      <el-col :span="8" class="text-right lable_bold">
                        {{ $t("Package")+":" }}
                      </el-col>
                      <el-col :span="16">
                        {{ display_package(prod) }}
                      </el-col>
                    </el-row>
                  </el-col>
                  <el-col :span="5">
                    <el-row :gutter="10">
                      <el-col :span="10" class="text-right lable_bold">
                        {{ $t("Inventory")+":" }}
                      </el-col>
                      <el-col :span="14">
                        {{ get_inventory_qty(prod.IDn) }}
                      </el-col>
                    </el-row>
                  </el-col>
                  <el-col :span="4">
                    <el-row :gutter="10">
                      <el-col :span="8" class="text-right lable_bold">
                        {{ $t("Qty")+":" }}
                      </el-col>
                      <el-col :span="16">
                        {{ prod.pack_qty ? prod.pack_qty.toFixed(2) : "" }}
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </div>
              <div class="mt-2" v-if="pack_type == 2 && bom.ToProducts">
                <el-row :gutter="10" v-for="prod in bom.ToProducts" :key="prod.IDn" :style="get_font_weight(prod.IDn)">
                  <el-col :span="5">
                    <el-row :gutter="10">
                      <el-col :span="8" class="text-right lable_bold">
                        PLU:
                      </el-col>
                      <el-col :span="16">
                        {{ prod.PLU }}
                      </el-col>
                    </el-row>
                  </el-col>
                  <el-col :span="5">
                    <el-row :gutter="10">
                      <el-col :span="8" class="text-right lable_bold">
                        {{ $t("Name")+":"}}
                      </el-col>
                      <el-col :span="16">
                        {{ getName1Name2_1(prod) }}
                      </el-col>
                    </el-row>
                  </el-col>
                  <el-col :span="5">
                    <el-row :gutter="10">
                      <el-col :span="8" class="text-right lable_bold">
                        {{ $t("Package")+":" }}
                      </el-col>
                      <el-col :span="16">
                        {{ display_package(prod) }}
                      </el-col>
                    </el-row>
                  </el-col>
                  <el-col :span="5">
                    <el-row :gutter="10">
                      <el-col :span="10" class="text-right lable_bold">
                        {{ $t("Inventory")+":" }}
                      </el-col>
                      <el-col :span="14">
                        {{ get_inventory_qty(prod.IDn) }}
                      </el-col>
                    </el-row>
                  </el-col>
                  <el-col :span="4">
                    <el-row :gutter="10">
                      <el-col :span="8" class="text-right lable_bold">
                        {{ $t("Qty")+":" }}
                      </el-col>
                      <el-col :span="16">
                        {{ prod.pack_qty ? prod.pack_qty.toFixed(2) : "" }}
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </div>
            </el-card>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  Button,
  Pagination,
  Collapse,
  CollapseItem,
  Row,
  Col,
  Tabs,
  TabPane,
  Carousel,
  CarouselItem,
  Card,
  Tooltip,
} from "element-ui";
// import InputAutocomplete from "../InputAutocomplete";
// import flatPicker from "vue-flatpickr-component";
// import "flatpickr/dist/flatpickr.css";
import { getStore, setStore, getName1Name2_1 } from "../../util/helper";
import { hasUtilityAuth } from "../../directives/acl";

const axios = require("axios");

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Pagination.name]: Pagination,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Row.name]: Row,
    [Col.name]: Col,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem,
    [Card.name]: Card,
    [Tooltip.name]: Tooltip,
    // flatPicker,
    // InputAutocomplete,
  },
  data() {
    return {
      order_header: "",

      usersidxlist: null,
      list_loading: false,

      list_current_page: 1,
      list_total: 0,
      list_per_page: 8,
      
      cur_list_opt: "",
      
      cur_pos_list: null,
      cur_ib_list: null,
      cur_ob_list: null,
      cur_pak_list: null,

      cur_LocationName: "",
      cur_PLU: "",
      cur_Name: "",
      cur_AverageCost: "",
      cur_APrice: "",
      cur_MinThreshold: "",
      cur_MaxThreshold: "",
      cur_Qty: "",
      cur_OnHold: "",
      cur_OnOrder: "",
      cur_StockValue: "",

      cur_VendorIDn: 0,
      cur_DepartmentIDn: 0,
      cur_CategoryIDn: 0,
      cur_Brand: "",
      cur_LocationID: "",
      cur_MinQty: "",
      cur_MaxQty: "",
      cur_Status: "",
      cur_categorylist: null,

      cur_order_type: "",
      cur_order: null,
      cur_order_products: [],

      cur_inventory: {
        IDn: "",
        LocationID: "",
        ProductIDn: "",
        Qty: "",
        LatestCost: "",
        AverageCost: "",
        CreateBy: "",
        UpdateBy: "",
        MinThreshold: "",
        MaxThreshold: "",
        Remarks: "",
        CreateDateTime: "",
        UpdateDateTime: "",
      },
      cur_product: {
        IDn: "",
        DepartmentIDn: "",
        CategoryIDn: "",
        CategoryID: "",
        PLU: "",
        Name1: "",
        Name2: "",
        ShortName: "",
        BtnColor: "#cad1d7",
        SortOrder: "999",
        Remarks: "",
        Description1: "",
        Description2: "",
        Brand: "",
        Barcode: "",
        SKU: "",
        Status: "Active",
        OriginCountry: "",
        Unit: "ea",
        UnitName: "ea",
        Weigh: "n",
        PackL: "1",
        PackM: "1",
        PackS: "1",
        Size: "",
        DimL: "1",
        DimM: "1",
        DimS: "1",
        DimUnit: "CM",
        NetWeight: "1",
        PackageWeight: "1",
        PackageWeightUnit: "ea",
        BoxSize: "",
        Attribute1: "",
        Attribute2: "",
        HasSerialNumber: "False",
        InventoryAutoUpdate: "True",
        Zoning: "",
        Attribute3: "",
        OnlineProduct: "",
        FixPrice: "",
        AisFixed: "",
        BisFixed: "",
        CisFixed: "",
        DisFixed: "",
        EisFixed: "",
        FisFixed: "",
        GisFixed: "",
        HisFixed: "",
        IisFixed: "",
        JisFixed: "",
        OnLinePriceSet: "",
        OnlineShowPrice: "True",
        APrice: "",
        BPrice: "",
        CPrice: "",
        DPrice: "",
        EPrice: "",
        FPrice: "",
        GPrice: "",
        HPrice: "",
        IPrice: "",
        JPrice: "",
        Discountable: "True",
        Type: "Product",
        ExpiryPeriod: "12",
        seq: "1234321",
        PrinterIDn: "1",
        IsCombo: 0,
        IsOpenPrice: 0,
        NonRevenue: 0,
        TaxTypeIDn: "0",
      },
      pack_type: 0,
      bom_title: "",
      pack_location: null,
      pack_product: null,
      sub_products: [],
      parent_products: [],
      pack_products: [],
      pack_inventorys:[],

      departmentlist: null,
      categorylist: null,
      productlist: null,
      brandlist: null,
      storelist: null,
      vendorlist: null,

      delay_timer: null,

      current_page: 1,
      product_total: 0,
      per_page: 20,

      token: "",
      user_id: 0,
    };
  },
  watch: {
    current_page: function (newval, oldval) {
      this.get_product_list();
    },
    cur_list_opt: function (newval, oldval) {
      if (this.cur_list_opt && this.cur_inventory) {
        this.load_list();
      }
    },
    list_current_page: function (newval, oldval) {
      if (this.cur_list_opt && this.cur_inventory) {
        this.load_list();
      }
    },
  },
  computed: {
    apiBaseUrl: function () {
      return axios.defaults.baseURL + "/Api";
    },
    cur_inventory_CreateDateTime: function () {
      if (
        this.cur_inventory.CreateDateTime != undefined &&
        this.cur_inventory.CreateDateTime
      ) {
        return this.cur_inventory.CreateDateTime.substring(0, 10);
      }
      return "";
    },
    cur_inventory_UpdateDateTime: function () {
      if (
        this.cur_inventory.UpdateDateTime != undefined &&
        this.cur_inventory.UpdateDateTime
      ) {
        return this.cur_inventory.UpdateDateTime.substring(0, 10);
      }
      return "";
    },
    cur_product_PackLMS: function () {
      if (
        this.cur_product.PackL != undefined &&
        this.cur_product.PackL &&
        this.cur_product.PackM != undefined &&
        this.cur_product.PackM &&
        this.cur_product.PackS != undefined &&
        this.cur_product.PackS
      ) {
        return (
          this.cur_product.PackL +
          "x" +
          this.cur_product.PackM +
          "x" +
          this.cur_product.PackS
        );
      }
      return "";
    },
    cur_product_DimLMS: function () {
      if (
        this.cur_product.DimL != undefined &&
        this.cur_product.DimL &&
        this.cur_product.DimM != undefined &&
        this.cur_product.DimM &&
        this.cur_product.DimS != undefined &&
        this.cur_product.DimS
      ) {
        return (
          this.cur_product.DimL +
          "x" +
          this.cur_product.DimM +
          "x" +
          this.cur_product.DimS
        );
      }
      return "";
    },
  },
  methods: {
    hasUtilityAuth,
    getName1Name2_1,
    get_inventory_qty(ProductIDn) {
      if (this.pack_inventorys && Object.keys(this.pack_inventorys).length > 0 && this.pack_inventorys[ProductIDn]) {
        return this.pack_inventorys[ProductIDn].Qty ? this.pack_inventorys[ProductIDn].Qty : "Err";
      }
      return "N/A";
    },
    get_font_weight(ProductIDn) {
      // console.log("get_font_weight", ProductIDn, this.pack_product.IDn);
      if (!this.pack_product) return {};
      if (ProductIDn == this.pack_product.IDn) {
        return {
          fontWeight: 600,
        };
      }
      return {};
    },
    display_package(product) {
      if (
        product.Weigh && (
          product.Weigh.toLowerCase() == "true" || 
          product.Weigh.toLowerCase() == "yes" || 
          product.Weigh.toLowerCase() == "y"
        )
      ) {
        return product.PackageWeightUnit ? product.PackageWeightUnit : "";
      } else {
        let box_size = "", pack_lms = "", unit = "", package_weight = "", package_unit = "";
        let t_1 = "", t_2 = "";
        
        box_size = product.BoxSize ? product.BoxSize : "";
        unit = product.Unit ? product.Unit : "";
        package_weight = product.PackageWeight && product.PackageWeight != 1 ? product.PackageWeight : "";
        package_unit = product.PackageWeightUnit ? product.PackageWeightUnit : "";
        if (
          product.PackL && product.PackM && product.PackS && 
          (product.PackL != 1 || product.PackM != 1 || product.PackS != 1)
        ) {
          pack_lms = (product.PackL + "X" + product.PackM + "X" + product.PackS);
        }
        // t_1
        // t_1 = (pack_lms && box_size ? (box_size == unit ? (pack_lms == unit ? "") : pack_lms + "/" + box_size) : pack_lms + box_size);
        if (box_size) {
          if (unit) {
            if (box_size == unit) {
              if (pack_lms) {
                t_1 = pack_lms + " " + unit;
              }
            } else {
              if (pack_lms) {
                t_1 = pack_lms + " " + unit + "/" + box_size;
              } else {
                t_1 = unit + "/" + box_size;
              }
            }
          } else {
            if (pack_lms) {
              t_1 = pack_lms + "/" + box_size;
            } else {
              unit = box_size;
            }
          }
        } else {
          if (unit) {
            if (pack_lms) {
              t_1 = pack_lms + " " + unit;
            }
          } else {
            t_1 = pack_lms;
          }
        }

        // t_2
        // t_2 = (package_weight && unit ? (unit == package_unit ? package_weight : package_weight + "/" + unit) : package_weight + unit);
        if (unit) {
          if (package_unit) {
            if (unit == package_unit) {
              if (package_weight) {
                t_2 = package_weight + " " + package_unit;
              } else {
                t_2 = package_unit;
              }
            } else {
              if (package_weight) {
                t_2 = package_weight + " " + package_unit + "/" + unit;
              } else {
                t_2 = package_unit + "/" + unit;
              }
            }
          } else {
            if (package_weight) {
              t_2 = package_weight + "/" + unit;
            } else {
              t_2 = unit;
            }
          }
        } else {
          if (package_unit) {
            if (package_weight) {
              t_2 = package_weight + " " + package_unit;
            } else {
              t_2 = package_unit;
            }
          } else {
            t_2 = package_weight;
          }
        }
        return t_1 && t_2 ? t_1 + ", " + t_2 : t_1 + t_2;
      }
    },
    order_no_func() {
      if (!this.cur_order_type) return "";
      switch (this.cur_order_type) {
          case "PO":
            return this.cur_order ? this.cur_order.OrderID : "";
          case "PR":
            return this.cur_order ? this.cur_order.OrderID : "";
          case "RE":
            return this.cur_order ? this.cur_order.OrderID : "";
          case "SO":
            return this.cur_order ? this.cur_order.OrderID : "";
          case "SR":
            return this.cur_order ? this.cur_order.OrderID : "";
          case "SP":
          case "UP":
          case "IB":
          case "OB":
          case "ST":
          case "SV":
          default:
            return "";
      }
    },
    order_date_func() {
      if (!this.cur_order_type) return "";
      switch (this.cur_order_type) {
          case "PO":
            return this.cur_order ? this.cur_order.OrderDate.split(" ")[0] : "";
          case "PR":
            return this.cur_order ? this.cur_order.ReturnDate.split(" ")[0] : "";
          case "RE":
            return this.cur_order ? this.cur_order.ReceiveDate.split(" ")[0] : "";
          case "SO":
            return this.cur_order ? this.cur_order.OrderDate.split(" ")[0] : "";
          case "SR":
            return this.cur_order ? this.cur_order.ReturnDate.split(" ")[0] : "";
          case "SP":
          case "UP":
          case "IB":
          case "OB":
          case "ST":
          case "SV":
          default:
            return "";
      }
    },
    order_cust_name_func () {
      if (!this.cur_order_type) return "";
      switch (this.cur_order_type) {
          case "PO":
          case "PR":
          case "RE":
            return this.$t("Vendor");
          case "SO":
          case "SR":
            return this.$t("Customer");
          case "SP":
          case "UP":
          case "IB":
          case "OB":
          case "ST":
          case "SV":
          default:
            return "";
      }
    },
    order_cust_func() {
      if (!this.cur_order_type) return "";
      switch (this.cur_order_type) {
          case "PO":
            return this.cur_order ? this.cur_order.PurchaseFName : "";
          case "PR":
            return this.cur_order ? this.cur_order.PurchaseFName : "";
          case "RE":
            return this.cur_order ? this.cur_order.PurchaseFName : "";
          case "SO":
            return this.cur_order ? this.cur_order.SoldToName : "";
          case "SR":
            // return this.cur_order ? this.cur_order.OrderID : "";\
            return "";
          case "SP":
          case "UP":
          case "IB":
          case "OB":
          case "ST":
          case "SV":
          default:
            return "";
      }
    },
    order_store_func() {
      if (!this.cur_order_type) return "";
      switch (this.cur_order_type) {
          case "PO":
            return this.cur_order ? this.cur_order.ComTitle : "";
          case "PR":
            return this.cur_order ? this.cur_order.ComTitle : "";
          case "RE":
            return this.cur_order ? this.cur_order.ComTitle : "";
          case "SO":
            return this.cur_order ? this.cur_order.ComTitle : "";
          case "SR":
            // return this.cur_order ? this.cur_order.OrderID : "";
            return "";
          case "SP":
          case "UP":
          case "IB":
          case "OB":
          case "ST":
          case "SV":
          default:
            return "";
      }
    },
    order_subt_func() {
      if (!this.cur_order_type) return "";
      switch (this.cur_order_type) {
          case "PO":
            return this.cur_order ? this.cur_order.SubTotal : "";
          case "PR":
            return this.cur_order ? this.cur_order.SubTotal : "";
          case "RE":
            return this.cur_order ? this.cur_order.SubTotal : "";
          case "SO":
            return this.cur_order ? this.cur_order.SubTotal : "";
          case "SR":
            return this.cur_order ? this.cur_order.SubTotal : "";
          case "SP":
          case "UP":
          case "IB":
          case "OB":
          case "ST":
          case "SV":
          default:
            return "";
      }
    },
    order_tax_func() {
      if (!this.cur_order_type) return "";
      switch (this.cur_order_type) {
          case "PO":
            return this.cur_order ? this.cur_order.TotalTax : "";
          case "PR":
            return this.cur_order ? this.cur_order.TotalTax : "";
          case "RE":
            return this.cur_order ? this.cur_order.TotalTax : "";
          case "SO":
            return this.cur_order ? this.cur_order.TotalTax : "";
          case "SR":
            return this.cur_order ? this.cur_order.TotalTax : "";
          case "SP":
          case "UP":
          case "IB":
          case "OB":
          case "ST":
          case "SV":
          default:
            return "";
      }
    },
    order_total_func() {
      if (!this.cur_order_type) return "";
      switch (this.cur_order_type) {
          case "PO":
            return this.cur_order ? this.cur_order.TotalAmount : "";
          case "PR":
            return this.cur_order ? this.cur_order.TotalAmount : "";
          case "RE":
            return this.cur_order ? this.cur_order.TotalAmount : "";
          case "SO":
            return this.cur_order ? this.cur_order.TotalAmount : "";
          case "SR":
            return this.cur_order ? this.cur_order.TotalAmount : "";
          case "SP":
          case "UP":
          case "IB":
          case "OB":
          case "ST":
          case "SV":
          default:
            return "";
      }
    },
    order_no_click(scope, no = 0) {
      this.$refs["inventory_carousel"].setActiveItem("order");
      // console.log("order_no_click",
      //   no == 0 ? scope.row.OrderNo : 
      //   no == 1 ? scope.row.OrderNo_1 : 
      //   no == 2 ? scope.row.OrderNo_2 : 
      //   "");
      if (!scope.row.OrderType) return;
      if (no == 0) {
        this.load_order(scope.row.OrderType, scope.row.OrderIDn); // for UP
      } else if (no == 1) {
        switch (scope.row.OrderType) {
          case "PO":
            this.load_order("PO", scope.row.OrderIDn_1);
            break;
          case "PR":
            this.load_order("PO", scope.row.OrderIDn_1);
            break;
          case "RE":
            this.load_order("PO", scope.row.OrderIDn_1);
            break;
          case "SO":
            this.load_order("SO", scope.row.OrderIDn_1);
            break;
          case "SR":
            this.load_order("SO", scope.row.OrderIDn_1);
            break;
          case "SP":
          case "UP":
          case "IB":
          case "OB":
          case "ST":
          case "SV":
          default:
            break;
        }
      } else if (no == 2) {
        switch (scope.row.OrderType) {
          case "PO":
            this.load_order("PO", scope.row.OrderIDn_1);
            break;
          case "PR":
            this.load_order("PR", scope.row.OrderIDn_2);
            break;
          case "RE":
            this.load_order("RE", scope.row.OrderIDn_2);
            break;
          case "SO":
            this.load_order("SO", scope.row.OrderIDn_1);
            break;
          case "SR":
            this.load_order("SR", scope.row.OrderIDn_2);
            break;
          case "SP":
          case "UP":
          case "IB":
          case "OB":
          case "ST":
          case "SV":
          default:
            break;
        }
      }
    },
    department_change() {
      this.cur_CategoryIDn = 0;
      if (this.cur_DepartmentIDn) {
        this.cur_categorylist = this.departmentlist[this.cur_DepartmentIDn]["Categorys"];
      } else {
        this.cur_categorylist = this.categorylist;
      }
    },
    load_order(orderType, orderIdn) {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("OrderType", orderType);
      bodyFormData.append("OrderIDn", orderIdn);
      var that = this;

      axios({
        method: "post",
        url: "/Api/Web/Inventory/Inventory/load_order",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_order_type = rt.data.orderType;
              that.cur_order = rt.data.order;
              that.cur_order_products = rt.data.products;
              that.order_header = that.cur_order_type;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    goBack() {
      this.$refs["inventory_carousel"].setActiveItem("inventory");
      // console.log("goBack");
    },
    list_opt_change(tab) {
      this.cur_list_opt = tab.name;
    },
    my_hide(event) {
      this.cur_list_opt = "";
      this.list_current_page = 1;
      this.cur_pos_list = null;
      this.cur_ib_list = null;
      this.cur_ob_list = null;
      this.cur_pak_list = null;
    },
    name_formatter(row, column) {
      if (row.Name1 && row.Name2) {
        return (row.Name1 ? row.Name1 : "") + (row.Name2 ? "(" + row.Name2 + ")" : "");
      } else if (row.ProductName1 && row.ProductName2) {
        return (row.ProductName1 ? row.ProductName1 : "") + (row.ProductName2 ? "(" + row.ProductName2 + ")" : "");
      } else if(column.label == this.$t('Base') && row.BaseName1 && row.BaseName2) {
        return (row.BaseName1 ? row.BaseName1 : "") + (row.BaseName2 ? "(" + row.BaseName2 + ")" : "");
      } else if (column.label == this.$t('Sub') && row.SubName1 && row.SubName2) {
        return (row.SubName1 ? row.SubName1 : "") + (row.SubName2 ? "(" + row.SubName2 + ")" : "");
      } else if(column.label == this.$t('From') && row.FromName1 && row.FromName2) {
        return (row.FromName1 ? row.FromName1 : "") + (row.FromName2 ? "(" + row.FromName2 + ")" : "");
      } else if (column.label == this.$t('To') && row.ToName1 && row.ToName2) {
        return (row.ToName1 ? row.ToName1 : "") + (row.ToName2 ? "(" + row.ToName2 + ")" : "");
      } else {
        return "";
      }
    },
    orderno_formatter(row, column) {
      return (row.OrderNo_1 ? row.OrderNo_1 : "") + (row.OrderNo_2 ? "(" + row.OrderNo_2 + ")" : "");
    },
    datetime_formatter(row, column) {
      let str = "";
      if (row.UpdateDateTime) {
        str = row.UpdateDateTime;
      } else if (row.StatusDateTime) {
        str = row.StatusDateTime;
      }
      let arr = str.split(" ");
      if (!arr || arr.length <= 0) return "";
      return arr[0];
    },
    by_formatter(row, column) {
      if (row.UpdateBy) {
        return this.usersidxlist[row.UpdateBy].UserName;
      } else if (row.InboundBy) {
        return this.usersidxlist[row.InboundBy].UserName;
      } else if (row.OutboundBy) {
        return this.usersidxlist[row.OutboundBy].UserName;
      } else {
        return "";
      }
    },
    unpack_formatter (row, column) {
      if (row.FromInventoryIDn && row.FromInventoryIDn == this.cur_inventory.IDn) {
        if (column.label == this.$t('Change') + ' ' + this.$t('Qty')) {
          return row.FromQtyChange;
        } else if (column.label == this.$t('Relate') + ' ' + this.$t('PLU')) {
          return row.ToPLU;
        } else if (column.label == this.$t('Relate')) {
          let p = [];
          p["label"] = this.$t("To");
          return this.name_formatter(row, p);
        } else if (column.label == this.$t('Relate') + ' ' + this.$t('Qty')) {
          return row.ToQtyChange;
        }
      } else if (row.ToInventoryIDn && row.ToInventoryIDn == this.cur_inventory.IDn) {
        if (column.label == this.$t('Change') + ' ' + this.$t('Qty')) {
          return row.ToQtyChange;
        } else if (column.label == this.$t('Relate') + ' ' + this.$t('PLU')) {
          return row.FromPLU;
        } else if (column.label == this.$t('Relate')) {
          let p = [];
          p["label"] = this.$t("From");
          return this.name_formatter(row, p);
        } else if (column.label == this.$t('Relate') + ' ' + this.$t('Qty')) {
          return row.FromQtyChange;
        }
      } else {
        return "";
      }
    },
    op_unit_formatter (row, column) {
      return row.Unit ? row.Unit : "";
    },
    op_price_formatter (row, column) {
      if (!this.cur_order_type) return "";
      switch (this.cur_order_type) {
          case "PO":
          case "PR":
          case "RE":
            return row.UnitCost ? row.UnitCost : ""
          case "SO":
          case "SR":
            return row.UnitPrice ? row.UnitPrice : ""
          case "SP":
          case "UP":
          case "IB":
          case "OB":
          case "ST":
          case "SV":
          default:
            return "";
      }
    },
    op_qty_formatter (row, column) {
      if (!this.cur_order_type) return "";
      switch (this.cur_order_type) {
          case "PO":
            return row.OrderQty ? row.OrderQty : ""
          case "PR":
            return row.ReturnQty ? row.ReturnQty : ""
          case "RE":
            return row.ReceiveQty ? row.ReceiveQty : ""
          case "SO":
            return row.OrderQty ? row.OrderQty : ""
          case "SR":
            return row.ReturnQty ? row.ReturnQty : ""
          case "SP":
          case "UP":
          case "IB":
          case "OB":
          case "ST":
          case "SV":
          default:
            return "";
      }
    },
    op_subt_formatter (row, column) {
      if (!this.cur_order_type) return "";
      switch (this.cur_order_type) {
          case "PO":
          case "PR":
          case "RE":
            return row.TotalCost ? row.TotalCost : ""
          case "SO":
          case "SR":
            return row.TotalPrice ? row.TotalPrice : ""
          case "SP":
          case "UP":
          case "IB":
          case "OB":
          case "ST":
          case "SV":
          default:
            return "";
      }
    },
    // created() {
    //   // console.log("created");
    // },
    // beforeMount() {
    //   // console.log("beforeMount");
    // },
    datetime_to_date(v) {
      // console.log(v)
      v.substring(0, 10);
    },
    showmedol() {
      this.$refs["my-modal"].show();
    },
    setinput_brand(val) {
      this.cur_Brand = val;
    },
    load_list() {
      this.list_loading = true;

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", this.cur_list_opt);
      bodyFormData.append("current_page", this.list_current_page);
      bodyFormData.append("per_page", this.list_per_page);
      if (this.cur_inventory && this.cur_inventory.IDn) bodyFormData.append("InventoryIDn", this.cur_inventory.IDn);
      if (this.cur_inventory && this.cur_inventory.LocationID) bodyFormData.append("LocationID", this.cur_inventory.LocationID);
      if (this.cur_inventory && this.cur_inventory.ProductIDn) bodyFormData.append("ProductIDn", this.cur_inventory.ProductIDn);
      var that = this;

      // console.log("load_list", this.cur_list_opt);

      axios({
        method: "post",
        url: "/Api/Web/Inventory/Inventory/load_list",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              if (that.cur_list_opt == "pos") {
                that.cur_pos_list = rt.data.list;
              } else if (that.cur_list_opt == "ib") {
                that.cur_ib_list = rt.data.list;
              } else if (that.cur_list_opt == "ob") {
                that.cur_ob_list = rt.data.list;
              } else if (that.cur_list_opt == "pak") {
                that.cur_pak_list = rt.data.list;
              }
              that.list_total = rt.data.list_total;
              that.list_loading = false;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    row_clicked_event(row, column, event) {
      // console.log("row_clicked_event", row, column, event);
      if (!hasUtilityAuth('InventoryEdit')) return;
      if (event) {
        event.preventDefault();
      }
      if (!column || (column.label == this.$t("Min.")) || (column.label == this.$t("Max.")) || (column.label == this.$t("In Stock"))) {
        return;
      }

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("IDn", row.IDn);
      bodyFormData.append("ProductIDn", row.ProductIDn);
      var that = this;

      axios({
        method: "post",
        url: "/Api/Web/Inventory/Inventory/edit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              //that.$router.push("/store");
              that.cur_product = rt.data.product;
              that.cur_inventory = rt.data.inventory;
              that.$refs["my-modal"].show();
              that.cur_list_opt = "pos";
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    check_packing(row) {
      let packQty = parseFloat(row.pack_qty);
      if (packQty <= 0) {
        row.pack_qty = 0;
        alert("Change Qty must be greater than 0.");
        return false;
      }
      return true;
    },
    do_packing(product, increase) {
      if (!confirm("Confirm to " + (increase ? "add " : "move away ") + product["pack_qty"] + (increase ? " to " : " from ") + 
          (product["Name2"] && product["Name2"] != "" ? product["Name1"] + " - " + product["Name2"] : product["Name1"]) + "?")) {
        return;
      }
      let tmp = {...this.pack_product};
      if (increase) {
        tmp["pack_qty"] = product["pack_qty"] * product["ToDivisor"] / product["ToMultiplier"];
        // tmp["pack_qty"] = product["pack_qty"] / product["ToRate"];
        // console.log("ToRate", product["ToRate"], "pack_qty", tmp["pack_qty"]);
      } else {
        tmp["pack_qty"] = product["pack_qty"] * product["FromMultiplier"] / product["FromDivisor"];
        // tmp["pack_qty"] = product["pack_qty"] * product["FromRate"];
        // console.log("FromRate", product["FromRate"], "pack_qty", tmp["pack_qty"]);
      }

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      if (increase) {
        bodyFormData.append("opt", 'unpack');
      } else {
        bodyFormData.append("opt", 'pack');
      }
      bodyFormData.append("Type", 0);
      bodyFormData.append("LocationID", this.pack_location.LocationID);
      bodyFormData.append("product_1", JSON.stringify(tmp));
      bodyFormData.append("product_2", JSON.stringify(product));

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Inventory/Inventory/dopacking",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.pack_location = rt.data.location;
              that.pack_product = rt.data.product;
              that.pack_products = rt.data.productlist;
              that.pack_inventorys = rt.data.inventorylist;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    check_bom(bom) {
      let pack_qty = parseFloat(bom.pack_qty);
      if (pack_qty <= 0) {
        bom.pack_qty = 0;
        alert("Change Qty must be greater than 0.");
        return false;
      }
      if (bom.FromProducts) {
        for (let i = 0; i < bom.FromProducts.length; i++) {
          bom.FromProducts[i].pack_qty = pack_qty * bom.FromProducts[i].Divisor / bom.FromProducts[i].Multiplier;
        }
      }
      if (bom.ToProducts) {
        for (let i = 0; i < bom.ToProducts.length; i++) {
          bom.ToProducts[i].pack_qty = pack_qty * bom.ToProducts[i].Multiplier / bom.ToProducts[i].Divisor;
        }
      }
      return true;
    },
    do_bom(bom, increase) {
      if (!confirm("Confirm to " + (increase ? "add " : "move away ") + bom["pack_qty"] + (increase ? " to " : " from ") + 
          (bom["Name2"] && bom["Name2"] != "" ? bom["Name1"] + " - " + bom["Name2"] : bom["Name1"]) + "?")) {
        return;
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", increase);
      bodyFormData.append("LocationID", this.pack_location.LocationID);
      bodyFormData.append("bom", JSON.stringify(bom));

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Inventory/Inventory/dobom",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.pack_location = rt.data.location;
              that.pack_product = rt.data.product;
              that.pack_products = rt.data.list;
              that.pack_inventorys = rt.data.inventorylist;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    packing(row, type) {
      this.pack_type = type;
      this.bom_title = 
        type == 0 ? "Packing / Unpacking" :
        type == 1 ? "BOM" :
        type == 2 ? this.$t("Dispose") :
        type == 3 ? this.$t("Combo") :
        "N/A";

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("ProductIDn", row.ProductIDn);
      bodyFormData.append("LocationID", row.LocationID);
      bodyFormData.append("Type", type);
      var that = this;

      axios({
        method: "post",
        url: "/Api/Web/Inventory/Inventory/getpackinfo",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              // console.log("packing type", type);
              if (type == 0) {
                that.pack_location = rt.data.location;
                that.pack_product = rt.data.product;
                that.pack_products = rt.data.productlist;
                that.pack_inventorys = rt.data.inventorylist;
                that.bom_title += "  -  Location: " + that.pack_location.LocationName;
                that.$refs["packing-modal"].show();
              } else if (type == 1 || type == 2 || type == 3) {
                that.pack_location = rt.data.location;
                that.pack_product = rt.data.product;
                that.pack_products = rt.data.list;
                that.pack_inventorys = rt.data.inventorylist;
                that.bom_title += "  -  Location: " + that.pack_location.LocationName;
                that.$refs["bom-modal"].show();
              } else {
                // console.warn("Value of Type is not valid");
              }
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    get_export_url() {
      let url = this.apiBaseUrl + "/Web/Inventory/Inventory/export";
      url += "?token=" + this.token;
      url += "&user_id=" + this.user_id;
      if (this.cur_VendorIDn) {
        url += "&VendorIDn=" + this.cur_;
      }
      if (this.cur_DepartmentIDn) {
        url += "&DepartmentIDn=" + this.cur_DepartmentIDn;
      }
      if (this.cur_CategoryIDn) {
        url += "&CategoryIDn=" + this.cur_CategoryIDn;
      }
      if (this.cur_Brand) {
        url += "&Brand=" + this.cur_Brand;
      }
      if (this.cur_LocationID) {
        url += "&LocationID=" + this.cur_LocationID;
      }
      if (this.cur_Status) {
        url += "&Status=" + this.cur_Status;
      }
      if (this.cur_MinQty) {
        url += "&MinQty=" + this.cur_MinQty;
      }
      if (this.cur_MaxQty) {
        url += "&MaxQty=" + this.cur_MaxQty;
      }

      return url;
    },
    async change_min_threshold(row) {
      // this will be called only after form is valid. You can do api call here to login
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("IDn", row.IDn);
      bodyFormData.append("ProductIDn", row.ProductIDn); // Dummy
      if (parseInt(row.MinThreshold)) {
        bodyFormData.append("opt", "update");
        bodyFormData.append("MinThreshold", parseInt(row.MinThreshold));
      }

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Inventory/Inventory/edit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_inventory = rt.data.inventory;
              that.get_product_list();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async change_max_threshold(row) {
      // this will be called only after form is valid. You can do api call here to login
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("IDn", row.IDn);
      bodyFormData.append("ProductIDn", row.ProductIDn); // Dummy
      if (parseInt(row.MaxThreshold)) {
        bodyFormData.append("opt", "update");
        bodyFormData.append("MaxThreshold", parseInt(row.MaxThreshold));
      }

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Inventory/Inventory/edit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_inventory = rt.data.inventory;
              that.get_product_list();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async submit_update() {
      // this will be called only after form is valid. You can do api call here to login
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      if (parseInt(this.cur_inventory.MinThreshold) || parseInt(this.cur_inventory.MaxThreshold) || this.cur_inventory.Remarks) {
        bodyFormData.append("opt", "update");
      }
      bodyFormData.append("IDn", this.cur_inventory.IDn);
      bodyFormData.append("ProductIDn", this.cur_inventory.ProductIDn); // Dummy
      if (this.cur_inventory.MinThreshold) {
        bodyFormData.append("MinThreshold", parseInt(this.cur_inventory.MinThreshold));
      }
      if (this.cur_inventory.MaxThreshold) {
        bodyFormData.append("MaxThreshold", parseInt(this.cur_inventory.MaxThreshold));
      }
      if (this.cur_inventory.Remarks) {
        bodyFormData.append("Remarks", this.cur_inventory.Remarks);
      }

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Inventory/Inventory/edit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_inventory = rt.data.inventory;
              that.get_product_list();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    get_product_list_delay() {
      if (this.delay_timer) {
        clearTimeout(this.delay_timer);
        this.delay_timer = null;
      }
      var that = this;
      this.delay_timer = setTimeout(() => {
        that.get_product_list();
      }, 400);
    },
    async get_product_list() {
      if (this.delay_timer) {
        clearTimeout(this.delay_timer);
        this.delay_timer = null;
      }

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      if (this.cur_VendorIDn) {
        bodyFormData.append("VendorIDn", this.cur_VendorIDn);
      }
      if (this.cur_DepartmentIDn) {
        bodyFormData.append("DepartmentIDn", this.cur_DepartmentIDn);
      }
      if (this.cur_CategoryIDn) {
        bodyFormData.append("CategoryIDn", this.cur_CategoryIDn);
      }
      if (this.cur_Brand) {
        bodyFormData.append("Brand", this.cur_Brand);
      }
      if (this.cur_LocationID) {
        bodyFormData.append("LocationID", this.cur_LocationID);
      }
      if (this.cur_Status) {
        bodyFormData.append("Status", this.cur_Status);
      }
      if (this.cur_MinQty) {
        bodyFormData.append("MinQty", this.cur_MinQty);
      }
      if (this.cur_MaxQty) {
        bodyFormData.append("MaxQty", this.cur_MaxQty);
      }

      if (this.cur_LocationName) {
        bodyFormData.append("LocationName", this.cur_LocationName);
      }
      if (this.cur_PLU) {
        bodyFormData.append("PLU", this.cur_PLU);
      }
      if (this.cur_Name) {
        bodyFormData.append("Name", this.cur_Name);
      }
      if (this.cur_AverageCost) {
        bodyFormData.append("AverageCost", this.cur_AverageCost);
      }
      if (this.cur_APrice) {
        bodyFormData.append("APrice", this.cur_APrice);
      }
      if (this.cur_MinThreshold) {
        bodyFormData.append("MinThreshold", this.cur_MinThreshold);
      }
      if (this.cur_MaxThreshold) {
        bodyFormData.append("MaxThreshold", this.cur_MaxThreshold);
      }
      if (this.cur_Qty) {
        bodyFormData.append("Qty", this.cur_Qty);
      }
      if (this.cur_OnHold) {
        bodyFormData.append("OnHold", this.cur_OnHold);
      }
      if (this.cur_OnOrder) {
        bodyFormData.append("OnOrder", this.cur_OnOrder);
      }
      if (this.cur_StockValue) {
        bodyFormData.append("StockValue", this.cur_StockValue);
      }

      bodyFormData.append("page", this.current_page);
      bodyFormData.append("per_page", this.per_page);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Inventory/Inventory",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.departmentlist = rt.data.departments;
              that.categorylist = rt.data.categorys;
              if(that.cur_DepartmentIDn) {
                that.cur_categorylist = that.departmentlist[that.cur_DepartmentIDn]["Categorys"];
              } else {
                that.cur_categorylist = that.categorylist;
              }
              that.productlist = rt.data.products;
              that.brandlist = rt.data.brands;
              that.storelist = rt.data.stores;
              that.vendorlist = rt.data.vendors;
              that.usersidxlist = rt.data.users;
              that.product_total = rt.data.product_total;
              that.per_page = rt.data.per_page;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },

    shortenName(product) {
      var name1 = this.shortName(product.Name1);
      var name2 = this.shortName(product.Name2);

      return name1 + (name2 ? " [" + name2 + "]" : "");
    },

    shortName(name) {
      var x;
      var length = 12;
      if (name && name.length > length) {
        x = name.substring(0, length - 2);
        x += "...";
      } else {
        x = name;
      }
      return x;
    },

    async init_data() {
      this.token = getStore("token");
      this.user_id = getStore("user_id");
      if (!this.token || !this.user_id) {
        this.$router.push("/login");
        return;
      }
      return this.get_product_list();
    },
  },
  mounted() {
    this.init_data();
    this.windowHeight = window.innerHeight;
  },
};
</script>
<style >
.content {
  padding-top: 40px;
}
.search-button {
  margin-top: 2rem;
}
.select_text {
  margin-left: -1rem;
}
.inventory .card .table th {
  padding: 0rem;
  background: none;
}
.inventory .card .table td {
  padding: 0rem;
}
.inventory .el-table th .cell {
  padding-left: 0px;
  padding-right: 0px;
}

.picimg {
  padding-left: 3rem;
  max-height: 6rem;
}
.el-collapse-item__header {
  background-color: #a5d1fa;
  font-size: 1.2rem;
  padding-left: 1rem;
}
.el-collapse-item__content {
  padding-bottom: 0.5rem;
}
#mymodalclass .modal-title {
  font-size: 2rem !important;
}
#mymodalclass .modal-body {
  padding-top: 0;
  padding-bottom: 0;
}
#mymodalclass2 .modal-title {
  font-size: 2rem !important;
}
#mymodalclass2 .modal-body {
  padding-top: 0;
  padding-bottom: 0;
}
.inventory-warrning {
  color: var(--danger);
}
.form-group {
  margin-bottom: 0.5rem;
}
.pack_unpack_div {
  border-top: 1px solid #808080;
  margin-top: .5rem;
}
.inventory .cell .p-0.el-input {
  border: 1px black solid;
  border-radius: 4px;
  width: calc(100% - 6px);
  margin: 2px;
  
}
.thead-light th .cell {
  text-align: center;
}
.order-title {
  margin-left: 1.5em;
  font-size: 1.3em;
}
</style>
